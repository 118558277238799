import React from "react"
import Layout from "../../components/Layout"
import Button from "../../components/Button"
import ToolBoxCard from "./../../components/Cards/ToolboxCard"
import SEO from "../../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import CommunityCards from "../../components/Cards/CommunityCards"
import NewsCards from "./../../components/Cards/NewsList"
import IconDoor from "./../../assets/brand/icons_door_red.svg"
import IconExtinguisher from "./../../assets/brand/icons_extinguisher_red.svg"
import IconSprinkler from "./../../assets/brand/icons_sprinkler_red.svg"
import IconSmokedetector from "./../../assets/brand/icons_smokedetector_red.svg"
import "./../../styles/dark-mode.scss"

export default ({
  data: {
    pageInfo: { mainTitle, mainDescription },
    resources: { nodes: resources },
    newsDescendingOrder: { nodes: newsDescendingOrder },
    communityPosts: { nodes: communityPosts },
  },
}) => {
  return (
    <Layout fluid={true} pageInfo={{ pageName: "First Responders Homepage" }}>
      <SEO
        title="First Responders"
        keywords={[`homepage`, `first responders`]}
      />
      <Container className={"section-one"} fluid={true}>
        <Container
          className={"highlight position-relative"}
          data-sal="fade"
          data-sal-duration="800"
          data-sal-delay="1200"
          data-sal-easing="ease"
        >
          <Row>
            <Col md={9} lg={7} className={"mr-auto order-1 order-md-0"}>
              <h1>{mainTitle}</h1>
              <p>{mainDescription}</p>
            </Col>
            <Col
              md={3}
              className={"order-0 order-md-1 text-right position-xs-absolute"}
            ></Col>
          </Row>
        </Container>
      </Container>
      <Container className={"section-two text-center"}>
        <Row className="icon-section-top d-flex">
          <Col
            className={"icon-section"}
            data-sal="slide-up"
            data-sal-duration="600"
            data-sal-delay="2000"
            data-sal-easing="ease"
          >
            <div className="s2-img-wrapper">
              <img
                className={"img-fluid text-center fsri-logo"}
                src={require("./../../assets/images/FSRI_UL_FULL_COLOR_LOGO.png")}
                alt={"logo"}
              />
            </div>
            <div className="text-left title py-3">
              Led by UL's Fire Safety Research Institute
            </div>
            <div className="text-left caption">
              Guided by a global advisory board comprised of fire service
              personnel, FSRI investigates residential, commercial, and
              industrial fires.
            </div>
          </Col>
          <Col
            className={"icon-section"}
            data-sal="slide-up"
            data-sal-duration="600"
            data-sal-delay="2000"
            data-sal-easing="ease"
          >
            <div className="s2-img-wrapper">
              <img
                className={"img-fluid text-center fsri-door"}
                src={require("./../../assets/images/fire-door.png")}
                alt={"logo"}
              />
            </div>
            <div className="text-left title py-3">
              Backed by Scientific Research
            </div>
            <div className="text-left caption">
              Full-scale experiments, field-testing, and predictive modeling
              replicate actual fires faced by firefighters on the fire ground.
            </div>
          </Col>
          <Col
            className={"icon-section"}
            data-sal="slide-up"
            data-sal-duration="600"
            data-sal-delay="2000"
            data-sal-easing="ease"
          >
            <div className="s2-img-wrapper">
              <img
                className={"img-fluid text-center cyd-logo"}
                src={require("../../assets/images/fp-about-cyd-logo.png")}
                alt={"logo"}
              />
            </div>
            <div className="text-left title py-3">
              Focused on Public Safety Education
            </div>
            <div className="text-left caption">
              After proving that a closed door could potentially save lives in a
              fire, FSRI is committed to share this finding with the world.
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="spacer"></Container>
      <Container
        className="toolbox"
        data-sal="fade"
        data-sal-duration="800"
        data-sal-delay="1200"
        data-sal-easing="ease"
      >
        <Row>
          <Col className={"pl-4 pt-5 pb-5 mr-5 pr-5"}>
            <h2>Toolbox</h2>
            <p>
              From printable flyers and refrigerator magnets, shareable graphics
              and videos, and even public relations resources, we’ve assembled a
              variety of materials safety influencers can use to make sure the
              message is heard loud and clear.
            </p>
            <Button
              to={`/first-responders/toolbox`}
              icon={`arrow-right`}
              className={`btn btn-bg-red`}
              variant={`btn-bg-red`}
              label={`Visit The Toolbox`}
            />
          </Col>
          <Col lg={3}>
            <ToolBoxCard {...resources[0]} />
          </Col>
          <Col lg={3} className={"ml-4 rc-m-2"}>
            <ToolBoxCard {...resources[20]} />
          </Col>
        </Row>
      </Container>
      <Container className={"px-0 pt-2 section section-top"}>
        <Row>
          <Col className={"d-none d-sm-block d-lg-none"}>
            <Col
              className={
                "d-flex justify-content-between align-items-center mb-4"
              }
            >
              <IconSmokedetector />
              <IconDoor />
            </Col>
            <Col
              className={"d-flex justify-content-between align-items-center"}
            >
              <IconExtinguisher />
              <IconSprinkler className={"mr-3"} />
            </Col>
          </Col>
          <Col
            lg={8}
            className={
              "d-none d-lg-flex justify-content-between align-items-center"
            }
            data-sal="slide-right"
            data-sal-duration="800"
            data-sal-delay="1500"
            data-sal-easing="ease"
          >
            <IconExtinguisher />
            <IconSprinkler />
            <IconSmokedetector />
            <IconDoor />
          </Col>
          <Col lg={4} md={6}>
            <h4>Not a first responder?</h4>
            <p>
              You can still put Close Before You Doze into action to help
              protect yourself and your loved ones.
            </p>
            <Button
              to={"/"}
              label={"Visit the Public Site"}
              icon={"arrow-right"}
              variant={"btn-bg-red"}
              className={"btn btn-bg-red"}
            />
          </Col>
        </Row>
      </Container>
      <Container className={"px-0 pt-4 section fp-hp-news"}>
        <h2>In the news</h2>
        <p>
          Closed doors are helping save lives. Learn about real-life incidents
          and see coverage of Close Before You Doze in action.
        </p>
        <Button
          to={"/first-responders/news"}
          label={"All News"}
          icon={"arrow-right"}
          className={"btn btn-bg-red"}
          variant={"btn-bg-red"}
        />
      </Container>
      <NewsCards fp={true} items={newsDescendingOrder} />
      <Container className={"px-0 pt-4 section our-community-preview"}>
        <Row>
          <Col lg={5} className={"mr-auto"}>
            <h2>Our community</h2>
            <p>
              See how first responders and others are sharing the Close Before
              You Doze message in new and creative ways.
            </p>
            <Button
              to={"/first-responders/community"}
              label={"View All Stories"}
              icon={"arrow-right"}
              className={"btn btn-bg-red"}
              variant={"btn-bg-red"}
            />
          </Col>
          <Col lg={4} className={"submit-story-callout pr-4 pt-4 text-right"}>
            <h4>Do you have a story to tell?</h4>
            <p>
              We want to hear from you so we can help share your story with our
              community and inspire others to join the Close Before You Doze
              movement.
            </p>
            <Button
              to={"/first-responders/submit-story"}
              label={"Tell Us Your Story"}
              icon={"arrow-right"}
              className={"btn btn-transparent-red"}
              variant={"btn-transparent-red"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CommunityCards label={"See Their Story"} items={communityPosts} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const FPHomeQuery = graphql`
  query FPHomeQuery {
    pageInfo: pagesYaml(slug: { eq: "fp-homepage" }) {
      mainTitle
      mainDescription
      backgroundImage
      imageFeatured1
      imageAltFeatured1
      titleFeatured1
      descriptionFeatured1
      imageFeatured2
      imageAltFeatured2
      titleFeatured2
      descriptionFeatured2
      imageFeatured3
      imageAltFeatured3
      titleFeatured3
      descriptionFeatured3
      cardTitleToolBox
      cardDescriptionToolBox
      cardCTAToolBox
      titleResource1
      descriptionResource1
      linkResource1
      titleResource2
      descriptionResource2
      linkResource2
      orderLinkResource2
      titlePublic
      descriptionPublic
      ctaPublic
      titleInTheNews
      descriptionInTheNews
      ctaInTheNews
      cardLimitInTheNews
      titleCommunity
      descriptionCommunity
      ctaCommunity
      cardLimitCommunity
      titleSubmitStory
      descriptionSubmitStory
      ctaSubmitStory
    }
    resources: allNodeToolboxResource(
      sort: { order: DESC, fields: [created] }
    ) {
      nodes {
        id
        title
        caption: field_caption
        published: created(formatString: "MMMM D, YYYY")
        link: field_link {
          uri
        }
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    newsDescendingOrder: allNodeNews(
      sort: { order: DESC, fields: [created] }
      limit: 3
    ) {
      nodes {
        id
        title
        caption: field_caption
        source: field_source
        published: created(formatString: "MMMM D, YYYY")
        link: field_link {
          uri
        }
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    communityPosts: allNodeCommunityPost(
      sort: { order: ASC, fields: [created] }
    ) {
      nodes {
        id
        title
        body: body {
          value
        }
        caption: field_caption
        published: created(formatString: "MMMM D, YYYY")
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
