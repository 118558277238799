import React from "react"
import { Card, Container, Row, Col } from "react-bootstrap"
import Button from "./../Button"
import "./style.scss"
import Img from "gatsby-image"

export default ({
  title,
  category,
  cta,
  link,
  imageAlt = "Missing Alt Text",
  relationships,
}) => {
  const imageThumb =
    relationships?.field_image?.relationships?.field_media_image?.thumbnail

  const TiledCard = () => (
    <>
      <div className="card toolbox resource">
        {imageThumb && (
          <Img
            key={"img-thumb-" + imageThumb.childImageSharp.fixed.originalName}
            loading={"lazy"}
            fixed={imageThumb.childImageSharp.fixed}
            alt={imageAlt}
          />
        )}
        <Container className={"labels"} fluid>
          <Row className={"pt-3 px-3 align-items-center"}>
            <Col>
              <Card.Text className={"resource-type"}>{category}</Card.Text>
            </Col>
            <Col className={"text-right"}></Col>
          </Row>
        </Container>
        <Card.Title className="p-3 mb-0">{title}</Card.Title>
        <Card.Text className={"px-3 caption"}></Card.Text>
        <Container className={"action-links"} fluid={true}>
          <div className="display-flex justify-space-between">
            <div>
              <Button
                onClick={link?.uri}
                icon={`arrow-down`}
                variant={"btn-transparent-red"}
                className={"btn-transparent-red"}
                label={cta}
                href={link?.uri}
                target={"_blank"}
                rel={"noopener noreferrer"}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  )

  return <TiledCard />
}
